import { Field, Form, Formik, FormikProps } from 'formik';
import { createRef, FC } from 'react';
import tw from 'twin.macro';
import * as Yup from 'yup';
import { Captcha } from '~/components/atoms/Captcha';
import { MegaField } from '~/components/atoms/MegaField';
import { Button } from '~/components/molecules/buttons';

/**
 * Form for consumer microsite
 */

export type ConsumerFormData = {
  name: string;
  consumerEmail: string;
  phone: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  inquiryReason: string;
  productPurchased: string;
  productPurchasedBrands: string;
  purchaseLocation: string;
  purchaseDate: string;
  claimSite: string;
  contactBy: string;
  additionalInfo: string;
  recaptcha: boolean;
};

const initialValues: ConsumerFormData = {
  name: '',
  consumerEmail: '',
  phone: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
  inquiryReason: '',
  productPurchased: '',
  productPurchasedBrands: '',
  purchaseLocation: '',
  purchaseDate: '',
  claimSite: '',
  contactBy: '',
  additionalInfo: '',
  recaptcha: false,
};

// Validates 10 or 11 digit phone numbers
const PHONE_REGEX =
  /^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
// Validates both 5 and 9 digit zip codes separated by dash
const ZIPCODE_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

const today = new Date();

const schema = Yup.object().shape({
  name: Yup.string().required(),
  consumerEmail: Yup.string().email().required(),
  phone: Yup.string().required().matches(PHONE_REGEX),
  addressLine1: Yup.string().required(),
  addressLine2: Yup.string(),
  city: Yup.string().required(),
  state: Yup.string().required(),
  zipCode: Yup.string().required().matches(ZIPCODE_REGEX),
  inquiryReason: Yup.string(),
  productPurchased: Yup.string().required(),
  productPurchasedBrands: Yup.string().required(),
  purchaseLocation: Yup.string().required(),
  purchaseDate: Yup.date().min('1990-01-01').max(today).required(),
  claimSite: Yup.string(),
  contactBy: Yup.string(),
  additionalInfo: Yup.string(),
  recaptcha: Yup.bool().is([true]).required(),
});

const options = {
  inquiryReason: [
    "It's been a long time. Where is my rebate?",
    'I need help submitting my rebate claim.',
    "What's required to meet the promotion terms?",
    "I'm missing a code or offer number.",
    'I just sent in my claim. When will I get my rebate?',
    "I'm not sure I'm in the right place.",
    'It’s about something else.',
  ],
  contactBy: ['Phone', 'Email'],
};

export type ConsumerFormProps = {
  onSubmit: (values) => Promise<void>;
  submitErrored?: boolean;
  submitErrorMessage?: string;
};

export const ConsumerForm: FC<ConsumerFormProps> = ({
  onSubmit,
  submitErrored,
  submitErrorMessage = 'Sorry, there was an error. Please try again.',
}) => {
  const formikRef = createRef<FormikProps<ConsumerFormData>>();

  const handleSubmit = async (values: ConsumerFormData, { setSubmitting }) => {
    // model payload by form type
    const payload = Object.keys(schema.fields).reduce(
      (data, key) => ({ ...data, [key]: values[key] }),
      {},
    );
    await onSubmit(payload);

    // formik callback - unless unmounted
    if (formikRef?.current) {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      validateOnMount
      validateOnBlur
      validateOnChange
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form css={formGridCss}>
          <MegaField required name="name" label="Full name" formik={formik} />

          <MegaField
            required
            type="email"
            name="consumerEmail"
            label="Email address"
            formik={formik}
          />

          <MegaField
            required
            type="tel"
            name="phone"
            label="Phone number"
            formik={formik}
          />

          <MegaField
            required
            doubleWide
            name="addressLine1"
            label="Address"
            formik={formik}
          />

          <MegaField
            doubleWide
            name="addressLine2"
            label="(Suite, Apt.,...)"
            formik={formik}
          />

          <MegaField required name="city" label="City" formik={formik} />

          <MegaField required name="state" label="State" formik={formik} />

          <MegaField required name="zipCode" label="Zip Code" formik={formik} />

          <MegaField
            doubleWide
            name="inquiryReason"
            label="Reason for my inquiry"
            formik={formik}
            options={options.inquiryReason}
          />

          <MegaField
            required
            name="productPurchased"
            label="Product purchased"
            formik={formik}
          />

          <MegaField
            required
            name="productPurchasedBrands"
            label="Brand(s) of products purchased"
            formik={formik}
          />

          <MegaField
            required
            name="purchaseLocation"
            label="Purchase location / store"
            formik={formik}
          />

          <MegaField
            required
            name="purchaseDate"
            type="date"
            label="Purchase date"
            formik={formik}
          />

          <MegaField
            doubleWide
            name="claimSite"
            label="What website address did you enter your claim on?"
            formik={formik}
          />

          <MegaField
            name="contactBy"
            label="Please contact me back by"
            formik={formik}
            options={options.contactBy}
          />

          <MegaField
            doubleWide
            name="additionalInfo"
            label="Any additional info you would like to provide?"
            formik={formik}
          />

          <div tw="grid-column[1/3] flex flex-col gap-4 mt-1.5 justify-between items-start md:(flex-row items-center)">
            <Field required as={Captcha} name="recaptcha" formik={formik} />
            <Button
              isSubmit
              flavor="doubleArrow"
              disabled={formik.isSubmitting}
              customCss={{
                width: '100%',
                maxWidth: '150px',
                maxHeight: '50px',
                justifyContent: 'space-between',
                padding: '1rem',
                borderRadius: '24px',
              }}
            >
              Submit
            </Button>

            {submitErrored && (
              <p tw="text-brand-red font-bold max-width[16rem]">
                Sorry, there was an error. Please try again.
              </p>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

const formGridCss = tw`
  block
  xl:(grid grid-template-columns[1fr 1fr] column-gap[3rem] row-gap[1.5rem])`;
