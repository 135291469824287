import { FC } from 'react';
import tw from 'twin.macro';
import RedLightbulb from '~/images/consumer-images/red-lightbulb.svg';

export type ConsumerCalloutProps = {
  heading: string;
  body: string;
};

/**
 * A callout box for the consumer microsite
 */
export const ConsumerCallout: FC<ConsumerCalloutProps> = ({
  heading,
  body,
}: ConsumerCalloutProps) => {
  return (
    <Container>
      <IconContainer>
        <RedLightbulb style={{ width: '100%', minWidth: '80px' }} />
      </IconContainer>
      <Content>
        <ContentHeader>{heading}</ContentHeader>
        <ContentBody>{body}</ContentBody>
      </Content>
    </Container>
  );
};

const Container = tw.div`
  flex
  flex-col
  justify-center
  items-center
  background-color[#ebebeb]
  gap-6
  p-8
  w-full
  margin[0 auto]
  sm:(
    flex-row
    justify-center
  )
`;
const IconContainer = tw.div`max-width[8rem]`;
const Content = tw.div`flex flex-col max-w-prose text-brand-blue-dark-foreground`;
const ContentHeader = tw.h2`text-center text-3xl sm:text-left`;
const ContentBody = tw.p`text-justify leading-6`;
