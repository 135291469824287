import { PageProps } from 'gatsby';
import { FC } from 'react';
import tw from 'twin.macro';
import { ConsumerPage } from '~/components/organisms/ConsumerPage';
import { Layout, SEO } from '~/components/wrappers';

/**
 * Consumer Microsite
 */

const Bolded = tw.span`font-bold`;
const BoldSpan = <Bolded>three business days</Bolded>;

const pageContext = {
  title: 'ACB Rebate Status Request',
  description:
    "If you don't have your original rebate form, you can use the form here to submit a rebate status request with the Advertising Checking Bureau, Inc.",
  keywords:
    'Consumer rebate request, rebate status request, what is my rebate status, rebate status',
};

const pageProps = {
  navLinks: [
    { url: 'https://acbcoop.com', name: 'Business Solutions' },
    { url: 'https://acbcoop.com/about-acb', name: 'About ACB' },
  ],
  headerHeading: "Let's check on your rebate status.",
  headerPrimaryText:
    "The Advertising Checking Bureau, Inc. works with many manufacturers and retailers to handle the fulfillment of rebate programs like the one you may be asking about now. We'd be happy to check if it's a program ACB handles—and find out more information for you if so.",
  formHeading: "We're here to help",
  formPrimaryText: (
    <>
      If you don't have your original rebate forms, please complete the form
      below, making sure to match what you submitted on your original rebate
      form. After you submit the form below, you will hear back directly from a
      member of our team by phone or email. Please allow up to {BoldSpan} to
      hear from us so that we can do a preliminary investigation before we
      reply.
    </>
  ),
  formCalloutHeading: 'TIP',
  formCalloutPrimaryText:
    'If you have your original rebate form, it should reference a website or a phone number to check the status of your rebate. That will be the quickest way to obtain your status.',
  formDisclaimer:
    'Information collected will be used to route your inquiry to the appropriate office or department within ACB for follow-up. Submitting your information gives ACB permission to contact you to resolve your inquiry.',
  submitErrorMessage: '',
  thanksHeading: 'Thank you',
  thanksPrimaryText:
    'Your request has been received. A member of our team will be in touch by phone or email. Please allow up to three business days to hear from us so that we can do a preliminary investigation before we reply.',
  businessCalloutHeading: 'Looking for ACB business solutions?',
  businessCalloutPrimaryText: (
    <>
      Please{' '}
      <a
        href="https://acbcoop.com"
        style={{ fontWeight: 'bold', color: '#D83832' }}
      >
        visit our channel marketing site
      </a>{' '}
      here.
    </>
  ),
  footerCopyright: `©${new Date().getFullYear()} The Advertising Checking Bureau, Inc.`,
  footerLinks: [
    { name: 'Business Solutions', url: 'https://www.acbcoop.com' },
    { name: 'About ACB', url: 'https://www.acbcoop.com/about-acb' },
    { name: 'Cookie Policy', url: 'https://www.acbcoop.com/cookie-policy' },
    { name: 'Privacy Policy', url: 'https://www.acbcoop.com/privacy-policy' },
    {
      name: 'Terms & Conditions',
      url: 'https://www.acbcoop.com/terms-and-conditions',
    },
  ],
};
const Page: FC<PageProps> = (props) => {
  return (
    <Layout>
      <SEO
        title={pageContext.title}
        description={pageContext.description}
        keywords={pageContext.keywords}
        path={props.location?.pathname ?? props.path}
      />
      <Container>
        <ConsumerPage
          navLinks={pageProps.navLinks}
          headerHeading={pageProps.headerHeading}
          headerPrimaryText={pageProps.headerPrimaryText}
          formHeading={pageProps.formHeading}
          formPrimaryText={pageProps.formPrimaryText}
          formCalloutHeading={pageProps.formCalloutHeading}
          formCalloutPrimaryText={pageProps.formCalloutPrimaryText}
          submitErrorMessage={pageProps.submitErrorMessage}
          thanksHeading={pageProps.thanksHeading}
          thanksPrimaryText={pageProps.thanksPrimaryText}
          formDisclaimer={pageProps.formDisclaimer}
          businessCalloutHeading={pageProps.businessCalloutHeading}
          businessCalloutPrimaryText={pageProps.businessCalloutPrimaryText}
          footerCopyright={pageProps.footerCopyright}
          footerLinks={pageProps.footerLinks}
        />
      </Container>
    </Layout>
  );
};

const Container = tw.div``;

export default Page;
